<template>
	<v-container>
		<v-row align="center">
			<v-col cols="6">
				<base-material-card
						color="green"
				>
					<template v-slot:heading>
						<div class="text-h3 font-weight-light">
							Создание новой акции
						</div>
					</template>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="sale.title" label="Название" placeholder="Название" outlined></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="sale.text" label="Текст акции" placeholder="Текст акции" outlined></v-textarea>
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="sale.link" label="Ссылка" placeholder="Ссылка" outlined></v-text-field>
						</v-col>
						<v-col cols="4">
							<v-text-field v-model="sale.promocode" label="Промик" placeholder="Промик" outlined></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="4">
							<v-btn @click="save()" color="green">Сохранить</v-btn>
						</v-col>
					</v-row>
				</base-material-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import httpClient from '../../services/http.service'

	export default {
		name: 'CreateSale',
		data(){
			return{
				sale: {},
			}
		},
		methods:{
			save(){
				const Querystring = require('querystring')
				console.log('strinify: ', Querystring.stringify(this.sale))
				httpClient.post('sales/create', Querystring.stringify(this.sale), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then(({data})=>{
					console.log('data:', data)
				})
				httpClient.get('sales').then(({data})=>{
					console.log('data:', data)
				})
			}
		}
	}
</script>

<style scoped>

</style>